import { Nation } from '@/types'
import { reactive, toRefs } from 'vue'

const store = reactive<{
  code: Nation
  showList: number[]
}>({
  code: Nation.BR,
  showList: [],
})

export function useStore() {
  return toRefs(store)
}
