import { RouteRecordRaw, createRouter, createWebHashHistory } from 'vue-router'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/:id',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/DHome.vue'),
  },
  {
    path: '/end',
    name: 'End',
    component: () => import(/* webpackChunkName: "about" */ '../views/DEnd.vue'),
  },
  {
    path: '/finish',
    name: 'Finish',
    component: () => import(/* webpackChunkName: "about" */ '../views/DEnd.vue'),
  },
  {
    path: '/preview',
    name: 'Preview',
    component: () => import(/* webpackChunkName: "about" */ '../views/DHome.vue'),
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

export default router
