export class MemoryStorage {
  data: Record<string, string>
  supportLocalStorage: boolean
  constructor() {
    this.data = {}
    this.supportLocalStorage = this.isSupportLocalStorage()
    this.init()
  }

  setItem(name: string, value: string) {
    if (this.supportLocalStorage) {
      return localStorage.setItem(name, value)
    }
    this.data[name] = value
    window.name = JSON.stringify(this.data)
  }

  getItem(name: string) {
    if (this.supportLocalStorage) {
      return localStorage.getItem(name)
    }
    return this.data[name]
  }

  removeItem(name: string) {
    if (this.supportLocalStorage) {
      return localStorage.removeItem(name)
    }
    delete this.data[name]
  }
  clear() {
    if (this.supportLocalStorage) {
      return localStorage.clear()
    }
    this.data = {}
  }

  isSupportLocalStorage() {
    try {
      window.localStorage.setItem('is_support_local_storage', 'true')
      window.localStorage.removeItem('is_support_local_storage')
      return true
    } catch {
      return false
    }
  }

  init() {
    if (this.supportLocalStorage) {
      return
    }
    try {
      this.data = JSON.parse(window.name)
    } catch {
      this.data = {}
    }
  }

  static instance: MemoryStorage

  static getInstance() {
    if (!MemoryStorage.instance) {
      MemoryStorage.instance = new MemoryStorage()
    }
    return MemoryStorage.instance
  }
}
