/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 3.19.4
 * source: int/http/hall/webview.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */

/*
    此处请修改为Creator NpmExport路径
    请注意路径的层级
 */
import * as pb_1 from "google-protobuf";
export namespace Webview {

    /** 
      通用请求头
       **/
    export class ReqHeader extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            timestamp?: number;
            user_id?: number;
            auth_token?: string;
            brand_code?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("timestamp" in data && data.timestamp != undefined) {
                    this.timestamp = data.timestamp;
                }
                if ("user_id" in data && data.user_id != undefined) {
                    this.user_id = data.user_id;
                }
                if ("auth_token" in data && data.auth_token != undefined) {
                    this.auth_token = data.auth_token;
                }
                if ("brand_code" in data && data.brand_code != undefined) {
                    this.brand_code = data.brand_code;
                }
            }
        }

        /** 
          当前时间截
           **/
        get timestamp() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }

        /** 
          当前时间截
           **/
        set timestamp(value: number) {
            pb_1.Message.setField(this, 1, value);
        }

        /** 
          当前登录用户id
           **/
        get user_id() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }

        /** 
          当前登录用户id
           **/
        set user_id(value: number) {
            pb_1.Message.setField(this, 2, value);
        }

        /** 
          授权凭证
           **/
        get auth_token() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }

        /** 
          授权凭证
           **/
        set auth_token(value: string) {
            pb_1.Message.setField(this, 3, value);
        }

        /** 
          品牌号
           **/
        get brand_code() {
            return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
        }

        /** 
          品牌号
           **/
        set brand_code(value: string) {
            pb_1.Message.setField(this, 4, value);
        }
        static fromObject(data: {
            timestamp?: number;
            user_id?: number;
            auth_token?: string;
            brand_code?: string;
        }): ReqHeader {
            const message = new ReqHeader({});
            if (data.timestamp != null) {
                message.timestamp = data.timestamp;
            }
            if (data.user_id != null) {
                message.user_id = data.user_id;
            }
            if (data.auth_token != null) {
                message.auth_token = data.auth_token;
            }
            if (data.brand_code != null) {
                message.brand_code = data.brand_code;
            }
            return message;
        }
        toObject() {
            const data: {
                timestamp?: number;
                user_id?: number;
                auth_token?: string;
                brand_code?: string;
            } = {};
            if (this.timestamp != null) {
                data.timestamp = this.timestamp;
            }
            if (this.user_id != null) {
                data.user_id = this.user_id;
            }
            if (this.auth_token != null) {
                data.auth_token = this.auth_token;
            }
            if (this.brand_code != null) {
                data.brand_code = this.brand_code;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.timestamp != 0)
                writer.writeInt64(1, this.timestamp);
            if (this.user_id != 0)
                writer.writeInt64(2, this.user_id);
            if (this.auth_token.length)
                writer.writeString(3, this.auth_token);
            if (this.brand_code.length)
                writer.writeString(4, this.brand_code);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ReqHeader {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ReqHeader();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.timestamp = reader.readInt64();
                        break;
                    case 2:
                        message.user_id = reader.readInt64();
                        break;
                    case 3:
                        message.auth_token = reader.readString();
                        break;
                    case 4:
                        message.brand_code = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): ReqHeader {
            return ReqHeader.deserialize(bytes);
        }
    }

    /** 
      通用应答头
       **/
    export class RespHeader extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            code?: number;
            trace_id?: string;
            msg?: string;
            timestamp?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("code" in data && data.code != undefined) {
                    this.code = data.code;
                }
                if ("trace_id" in data && data.trace_id != undefined) {
                    this.trace_id = data.trace_id;
                }
                if ("msg" in data && data.msg != undefined) {
                    this.msg = data.msg;
                }
                if ("timestamp" in data && data.timestamp != undefined) {
                    this.timestamp = data.timestamp;
                }
            }
        }

        /** 
          各模块接口定义的业务异常码
           **/
        get code() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }

        /** 
          各模块接口定义的业务异常码
           **/
        set code(value: number) {
            pb_1.Message.setField(this, 1, value);
        }

        /** 
          本次请求唯一事务id，用于问题追踪定位
           **/
        get trace_id() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }

        /** 
          本次请求唯一事务id，用于问题追踪定位
           **/
        set trace_id(value: string) {
            pb_1.Message.setField(this, 2, value);
        }

        /** 
          错误信息
           **/
        get msg() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }

        /** 
          错误信息
           **/
        set msg(value: string) {
            pb_1.Message.setField(this, 3, value);
        }

        /** 
          当前时间截
           **/
        get timestamp() {
            return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
        }

        /** 
          当前时间截
           **/
        set timestamp(value: number) {
            pb_1.Message.setField(this, 4, value);
        }
        static fromObject(data: {
            code?: number;
            trace_id?: string;
            msg?: string;
            timestamp?: number;
        }): RespHeader {
            const message = new RespHeader({});
            if (data.code != null) {
                message.code = data.code;
            }
            if (data.trace_id != null) {
                message.trace_id = data.trace_id;
            }
            if (data.msg != null) {
                message.msg = data.msg;
            }
            if (data.timestamp != null) {
                message.timestamp = data.timestamp;
            }
            return message;
        }
        toObject() {
            const data: {
                code?: number;
                trace_id?: string;
                msg?: string;
                timestamp?: number;
            } = {};
            if (this.code != null) {
                data.code = this.code;
            }
            if (this.trace_id != null) {
                data.trace_id = this.trace_id;
            }
            if (this.msg != null) {
                data.msg = this.msg;
            }
            if (this.timestamp != null) {
                data.timestamp = this.timestamp;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.code != 0)
                writer.writeInt32(1, this.code);
            if (this.trace_id.length)
                writer.writeString(2, this.trace_id);
            if (this.msg.length)
                writer.writeString(3, this.msg);
            if (this.timestamp != 0)
                writer.writeInt64(4, this.timestamp);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): RespHeader {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new RespHeader();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.code = reader.readInt32();
                        break;
                    case 2:
                        message.trace_id = reader.readString();
                        break;
                    case 3:
                        message.msg = reader.readString();
                        break;
                    case 4:
                        message.timestamp = reader.readInt64();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): RespHeader {
            return RespHeader.deserialize(bytes);
        }
    }

    /** 
      分页参数
       **/
    export class PaginationCriteria extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            page_no?: number;
            page_size?: number;
            return_total?: boolean;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("page_no" in data && data.page_no != undefined) {
                    this.page_no = data.page_no;
                }
                if ("page_size" in data && data.page_size != undefined) {
                    this.page_size = data.page_size;
                }
                if ("return_total" in data && data.return_total != undefined) {
                    this.return_total = data.return_total;
                }
            }
        }
        get page_no() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set page_no(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        get page_size() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set page_size(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        get return_total() {
            return pb_1.Message.getFieldWithDefault(this, 3, false) as boolean;
        }
        set return_total(value: boolean) {
            pb_1.Message.setField(this, 3, value);
        }
        static fromObject(data: {
            page_no?: number;
            page_size?: number;
            return_total?: boolean;
        }): PaginationCriteria {
            const message = new PaginationCriteria({});
            if (data.page_no != null) {
                message.page_no = data.page_no;
            }
            if (data.page_size != null) {
                message.page_size = data.page_size;
            }
            if (data.return_total != null) {
                message.return_total = data.return_total;
            }
            return message;
        }
        toObject() {
            const data: {
                page_no?: number;
                page_size?: number;
                return_total?: boolean;
            } = {};
            if (this.page_no != null) {
                data.page_no = this.page_no;
            }
            if (this.page_size != null) {
                data.page_size = this.page_size;
            }
            if (this.return_total != null) {
                data.return_total = this.return_total;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.page_no != 0)
                writer.writeInt32(1, this.page_no);
            if (this.page_size != 0)
                writer.writeInt32(2, this.page_size);
            if (this.return_total != false)
                writer.writeBool(3, this.return_total);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): PaginationCriteria {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new PaginationCriteria();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.page_no = reader.readInt32();
                        break;
                    case 2:
                        message.page_size = reader.readInt32();
                        break;
                    case 3:
                        message.return_total = reader.readBool();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): PaginationCriteria {
            return PaginationCriteria.deserialize(bytes);
        }
    }

    /** 
      ========================== API
       问卷系统
       act/survey/get/
       **/
    export class GetSurveyReq extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            header?: ReqHeader;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("header" in data && data.header != undefined) {
                    this.header = data.header;
                }
            }
        }
        get header() {
            return pb_1.Message.getWrapperField(this, ReqHeader, 1) as ReqHeader;
        }
        set header(value: ReqHeader) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get has_header() {
            return pb_1.Message.getField(this, 1) != null;
        }
        static fromObject(data: {
            header?: ReturnType<typeof ReqHeader.prototype.toObject>;
        }): GetSurveyReq {
            const message = new GetSurveyReq({});
            if (data.header != null) {
                message.header = ReqHeader.fromObject(data.header);
            }
            return message;
        }
        toObject() {
            const data: {
                header?: ReturnType<typeof ReqHeader.prototype.toObject>;
            } = {};
            if (this.header != null) {
                data.header = this.header.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.has_header)
                writer.writeMessage(1, this.header, () => this.header.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetSurveyReq {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetSurveyReq();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.header, () => message.header = ReqHeader.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): GetSurveyReq {
            return GetSurveyReq.deserialize(bytes);
        }
    }
    export class SurveyConf extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            id?: number;
            survey_id?: number;
            survey_url?: string;
            effected_at?: number;
            expired_at?: number;
            survey?: string;
            created_at?: number;
            updated_at?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("id" in data && data.id != undefined) {
                    this.id = data.id;
                }
                if ("survey_id" in data && data.survey_id != undefined) {
                    this.survey_id = data.survey_id;
                }
                if ("survey_url" in data && data.survey_url != undefined) {
                    this.survey_url = data.survey_url;
                }
                if ("effected_at" in data && data.effected_at != undefined) {
                    this.effected_at = data.effected_at;
                }
                if ("expired_at" in data && data.expired_at != undefined) {
                    this.expired_at = data.expired_at;
                }
                if ("survey" in data && data.survey != undefined) {
                    this.survey = data.survey;
                }
                if ("created_at" in data && data.created_at != undefined) {
                    this.created_at = data.created_at;
                }
                if ("updated_at" in data && data.updated_at != undefined) {
                    this.updated_at = data.updated_at;
                }
            }
        }
        get id() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set id(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        get survey_id() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set survey_id(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        get survey_url() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set survey_url(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        get effected_at() {
            return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
        }
        set effected_at(value: number) {
            pb_1.Message.setField(this, 4, value);
        }
        get expired_at() {
            return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
        }
        set expired_at(value: number) {
            pb_1.Message.setField(this, 5, value);
        }
        get survey() {
            return pb_1.Message.getFieldWithDefault(this, 6, "") as string;
        }
        set survey(value: string) {
            pb_1.Message.setField(this, 6, value);
        }
        get created_at() {
            return pb_1.Message.getFieldWithDefault(this, 7, 0) as number;
        }
        set created_at(value: number) {
            pb_1.Message.setField(this, 7, value);
        }
        get updated_at() {
            return pb_1.Message.getFieldWithDefault(this, 8, 0) as number;
        }
        set updated_at(value: number) {
            pb_1.Message.setField(this, 8, value);
        }
        static fromObject(data: {
            id?: number;
            survey_id?: number;
            survey_url?: string;
            effected_at?: number;
            expired_at?: number;
            survey?: string;
            created_at?: number;
            updated_at?: number;
        }): SurveyConf {
            const message = new SurveyConf({});
            if (data.id != null) {
                message.id = data.id;
            }
            if (data.survey_id != null) {
                message.survey_id = data.survey_id;
            }
            if (data.survey_url != null) {
                message.survey_url = data.survey_url;
            }
            if (data.effected_at != null) {
                message.effected_at = data.effected_at;
            }
            if (data.expired_at != null) {
                message.expired_at = data.expired_at;
            }
            if (data.survey != null) {
                message.survey = data.survey;
            }
            if (data.created_at != null) {
                message.created_at = data.created_at;
            }
            if (data.updated_at != null) {
                message.updated_at = data.updated_at;
            }
            return message;
        }
        toObject() {
            const data: {
                id?: number;
                survey_id?: number;
                survey_url?: string;
                effected_at?: number;
                expired_at?: number;
                survey?: string;
                created_at?: number;
                updated_at?: number;
            } = {};
            if (this.id != null) {
                data.id = this.id;
            }
            if (this.survey_id != null) {
                data.survey_id = this.survey_id;
            }
            if (this.survey_url != null) {
                data.survey_url = this.survey_url;
            }
            if (this.effected_at != null) {
                data.effected_at = this.effected_at;
            }
            if (this.expired_at != null) {
                data.expired_at = this.expired_at;
            }
            if (this.survey != null) {
                data.survey = this.survey;
            }
            if (this.created_at != null) {
                data.created_at = this.created_at;
            }
            if (this.updated_at != null) {
                data.updated_at = this.updated_at;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.id != 0)
                writer.writeInt64(1, this.id);
            if (this.survey_id != 0)
                writer.writeInt64(2, this.survey_id);
            if (this.survey_url.length)
                writer.writeString(3, this.survey_url);
            if (this.effected_at != 0)
                writer.writeInt64(4, this.effected_at);
            if (this.expired_at != 0)
                writer.writeInt64(5, this.expired_at);
            if (this.survey.length)
                writer.writeString(6, this.survey);
            if (this.created_at != 0)
                writer.writeInt64(7, this.created_at);
            if (this.updated_at != 0)
                writer.writeInt64(8, this.updated_at);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SurveyConf {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SurveyConf();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.id = reader.readInt64();
                        break;
                    case 2:
                        message.survey_id = reader.readInt64();
                        break;
                    case 3:
                        message.survey_url = reader.readString();
                        break;
                    case 4:
                        message.effected_at = reader.readInt64();
                        break;
                    case 5:
                        message.expired_at = reader.readInt64();
                        break;
                    case 6:
                        message.survey = reader.readString();
                        break;
                    case 7:
                        message.created_at = reader.readInt64();
                        break;
                    case 8:
                        message.updated_at = reader.readInt64();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): SurveyConf {
            return SurveyConf.deserialize(bytes);
        }
    }
    export class GetSurveyResp extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            header?: RespHeader;
            data?: GetSurveyResp.RespData;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("header" in data && data.header != undefined) {
                    this.header = data.header;
                }
                if ("data" in data && data.data != undefined) {
                    this.data = data.data;
                }
            }
        }
        get header() {
            return pb_1.Message.getWrapperField(this, RespHeader, 1) as RespHeader;
        }
        set header(value: RespHeader) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get has_header() {
            return pb_1.Message.getField(this, 1) != null;
        }
        get data() {
            return pb_1.Message.getWrapperField(this, GetSurveyResp.RespData, 2) as GetSurveyResp.RespData;
        }
        set data(value: GetSurveyResp.RespData) {
            pb_1.Message.setWrapperField(this, 2, value);
        }
        get has_data() {
            return pb_1.Message.getField(this, 2) != null;
        }
        static fromObject(data: {
            header?: ReturnType<typeof RespHeader.prototype.toObject>;
            data?: ReturnType<typeof GetSurveyResp.RespData.prototype.toObject>;
        }): GetSurveyResp {
            const message = new GetSurveyResp({});
            if (data.header != null) {
                message.header = RespHeader.fromObject(data.header);
            }
            if (data.data != null) {
                message.data = GetSurveyResp.RespData.fromObject(data.data);
            }
            return message;
        }
        toObject() {
            const data: {
                header?: ReturnType<typeof RespHeader.prototype.toObject>;
                data?: ReturnType<typeof GetSurveyResp.RespData.prototype.toObject>;
            } = {};
            if (this.header != null) {
                data.header = this.header.toObject();
            }
            if (this.data != null) {
                data.data = this.data.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.has_header)
                writer.writeMessage(1, this.header, () => this.header.serialize(writer));
            if (this.has_data)
                writer.writeMessage(2, this.data, () => this.data.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetSurveyResp {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetSurveyResp();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.header, () => message.header = RespHeader.deserialize(reader));
                        break;
                    case 2:
                        reader.readMessage(message.data, () => message.data = GetSurveyResp.RespData.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): GetSurveyResp {
            return GetSurveyResp.deserialize(bytes);
        }
    }
    export namespace GetSurveyResp {
        export class RespData extends pb_1.Message {
            #one_of_decls: number[][] = [];
            constructor(data?: any[] | {
                conf?: SurveyConf;
                left_ts?: number;
            }) {
                super();
                pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
                if (!Array.isArray(data) && typeof data == "object") {
                    if ("conf" in data && data.conf != undefined) {
                        this.conf = data.conf;
                    }
                    if ("left_ts" in data && data.left_ts != undefined) {
                        this.left_ts = data.left_ts;
                    }
                }
            }
            get conf() {
                return pb_1.Message.getWrapperField(this, SurveyConf, 1) as SurveyConf;
            }
            set conf(value: SurveyConf) {
                pb_1.Message.setWrapperField(this, 1, value);
            }
            get has_conf() {
                return pb_1.Message.getField(this, 1) != null;
            }
            get left_ts() {
                return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
            }
            set left_ts(value: number) {
                pb_1.Message.setField(this, 2, value);
            }
            static fromObject(data: {
                conf?: ReturnType<typeof SurveyConf.prototype.toObject>;
                left_ts?: number;
            }): RespData {
                const message = new RespData({});
                if (data.conf != null) {
                    message.conf = SurveyConf.fromObject(data.conf);
                }
                if (data.left_ts != null) {
                    message.left_ts = data.left_ts;
                }
                return message;
            }
            toObject() {
                const data: {
                    conf?: ReturnType<typeof SurveyConf.prototype.toObject>;
                    left_ts?: number;
                } = {};
                if (this.conf != null) {
                    data.conf = this.conf.toObject();
                }
                if (this.left_ts != null) {
                    data.left_ts = this.left_ts;
                }
                return data;
            }
            serialize(): Uint8Array;
            serialize(w: pb_1.BinaryWriter): void;
            serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
                const writer = w || new pb_1.BinaryWriter();
                if (this.has_conf)
                    writer.writeMessage(1, this.conf, () => this.conf.serialize(writer));
                if (this.left_ts != 0)
                    writer.writeInt64(2, this.left_ts);
                if (!w)
                    return writer.getResultBuffer();
            }
            static deserialize(bytes: Uint8Array | pb_1.BinaryReader): RespData {
                const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new RespData();
                while (reader.nextField()) {
                    if (reader.isEndGroup())
                        break;
                    switch (reader.getFieldNumber()) {
                        case 1:
                            reader.readMessage(message.conf, () => message.conf = SurveyConf.deserialize(reader));
                            break;
                        case 2:
                            message.left_ts = reader.readInt64();
                            break;
                        default: reader.skipField();
                    }
                }
                return message;
            }
            serializeBinary(): Uint8Array {
                return this.serialize();
            }
            static deserializeBinary(bytes: Uint8Array): RespData {
                return RespData.deserialize(bytes);
            }
        }
    }

    /** 
      act/survey/submit/
       **/
    export class SubmitSurveyReq extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            header?: ReqHeader;
            survey_conf_id?: number;
            write_time?: number;
            question_content?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("header" in data && data.header != undefined) {
                    this.header = data.header;
                }
                if ("survey_conf_id" in data && data.survey_conf_id != undefined) {
                    this.survey_conf_id = data.survey_conf_id;
                }
                if ("write_time" in data && data.write_time != undefined) {
                    this.write_time = data.write_time;
                }
                if ("question_content" in data && data.question_content != undefined) {
                    this.question_content = data.question_content;
                }
            }
        }
        get header() {
            return pb_1.Message.getWrapperField(this, ReqHeader, 1) as ReqHeader;
        }
        set header(value: ReqHeader) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get has_header() {
            return pb_1.Message.getField(this, 1) != null;
        }
        get survey_conf_id() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set survey_conf_id(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        get write_time() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set write_time(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        get question_content() {
            return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
        }
        set question_content(value: string) {
            pb_1.Message.setField(this, 4, value);
        }
        static fromObject(data: {
            header?: ReturnType<typeof ReqHeader.prototype.toObject>;
            survey_conf_id?: number;
            write_time?: number;
            question_content?: string;
        }): SubmitSurveyReq {
            const message = new SubmitSurveyReq({});
            if (data.header != null) {
                message.header = ReqHeader.fromObject(data.header);
            }
            if (data.survey_conf_id != null) {
                message.survey_conf_id = data.survey_conf_id;
            }
            if (data.write_time != null) {
                message.write_time = data.write_time;
            }
            if (data.question_content != null) {
                message.question_content = data.question_content;
            }
            return message;
        }
        toObject() {
            const data: {
                header?: ReturnType<typeof ReqHeader.prototype.toObject>;
                survey_conf_id?: number;
                write_time?: number;
                question_content?: string;
            } = {};
            if (this.header != null) {
                data.header = this.header.toObject();
            }
            if (this.survey_conf_id != null) {
                data.survey_conf_id = this.survey_conf_id;
            }
            if (this.write_time != null) {
                data.write_time = this.write_time;
            }
            if (this.question_content != null) {
                data.question_content = this.question_content;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.has_header)
                writer.writeMessage(1, this.header, () => this.header.serialize(writer));
            if (this.survey_conf_id != 0)
                writer.writeInt64(2, this.survey_conf_id);
            if (this.write_time != 0)
                writer.writeInt64(3, this.write_time);
            if (this.question_content.length)
                writer.writeString(4, this.question_content);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SubmitSurveyReq {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SubmitSurveyReq();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.header, () => message.header = ReqHeader.deserialize(reader));
                        break;
                    case 2:
                        message.survey_conf_id = reader.readInt64();
                        break;
                    case 3:
                        message.write_time = reader.readInt64();
                        break;
                    case 4:
                        message.question_content = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): SubmitSurveyReq {
            return SubmitSurveyReq.deserialize(bytes);
        }
    }
    export class SubmitSurveyResp extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            header?: RespHeader;
            data?: SubmitSurveyResp.RespData;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("header" in data && data.header != undefined) {
                    this.header = data.header;
                }
                if ("data" in data && data.data != undefined) {
                    this.data = data.data;
                }
            }
        }
        get header() {
            return pb_1.Message.getWrapperField(this, RespHeader, 1) as RespHeader;
        }
        set header(value: RespHeader) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get has_header() {
            return pb_1.Message.getField(this, 1) != null;
        }
        get data() {
            return pb_1.Message.getWrapperField(this, SubmitSurveyResp.RespData, 2) as SubmitSurveyResp.RespData;
        }
        set data(value: SubmitSurveyResp.RespData) {
            pb_1.Message.setWrapperField(this, 2, value);
        }
        get has_data() {
            return pb_1.Message.getField(this, 2) != null;
        }
        static fromObject(data: {
            header?: ReturnType<typeof RespHeader.prototype.toObject>;
            data?: ReturnType<typeof SubmitSurveyResp.RespData.prototype.toObject>;
        }): SubmitSurveyResp {
            const message = new SubmitSurveyResp({});
            if (data.header != null) {
                message.header = RespHeader.fromObject(data.header);
            }
            if (data.data != null) {
                message.data = SubmitSurveyResp.RespData.fromObject(data.data);
            }
            return message;
        }
        toObject() {
            const data: {
                header?: ReturnType<typeof RespHeader.prototype.toObject>;
                data?: ReturnType<typeof SubmitSurveyResp.RespData.prototype.toObject>;
            } = {};
            if (this.header != null) {
                data.header = this.header.toObject();
            }
            if (this.data != null) {
                data.data = this.data.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.has_header)
                writer.writeMessage(1, this.header, () => this.header.serialize(writer));
            if (this.has_data)
                writer.writeMessage(2, this.data, () => this.data.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SubmitSurveyResp {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SubmitSurveyResp();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.header, () => message.header = RespHeader.deserialize(reader));
                        break;
                    case 2:
                        reader.readMessage(message.data, () => message.data = SubmitSurveyResp.RespData.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): SubmitSurveyResp {
            return SubmitSurveyResp.deserialize(bytes);
        }
    }
    export namespace SubmitSurveyResp {
        export class BonusData extends pb_1.Message {
            #one_of_decls: number[][] = [];
            constructor(data?: any[] | {
                props_id?: number;
                props_val?: number;
            }) {
                super();
                pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
                if (!Array.isArray(data) && typeof data == "object") {
                    if ("props_id" in data && data.props_id != undefined) {
                        this.props_id = data.props_id;
                    }
                    if ("props_val" in data && data.props_val != undefined) {
                        this.props_val = data.props_val;
                    }
                }
            }
            get props_id() {
                return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
            }
            set props_id(value: number) {
                pb_1.Message.setField(this, 1, value);
            }
            get props_val() {
                return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
            }
            set props_val(value: number) {
                pb_1.Message.setField(this, 2, value);
            }
            static fromObject(data: {
                props_id?: number;
                props_val?: number;
            }): BonusData {
                const message = new BonusData({});
                if (data.props_id != null) {
                    message.props_id = data.props_id;
                }
                if (data.props_val != null) {
                    message.props_val = data.props_val;
                }
                return message;
            }
            toObject() {
                const data: {
                    props_id?: number;
                    props_val?: number;
                } = {};
                if (this.props_id != null) {
                    data.props_id = this.props_id;
                }
                if (this.props_val != null) {
                    data.props_val = this.props_val;
                }
                return data;
            }
            serialize(): Uint8Array;
            serialize(w: pb_1.BinaryWriter): void;
            serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
                const writer = w || new pb_1.BinaryWriter();
                if (this.props_id != 0)
                    writer.writeInt64(1, this.props_id);
                if (this.props_val != 0)
                    writer.writeInt64(2, this.props_val);
                if (!w)
                    return writer.getResultBuffer();
            }
            static deserialize(bytes: Uint8Array | pb_1.BinaryReader): BonusData {
                const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new BonusData();
                while (reader.nextField()) {
                    if (reader.isEndGroup())
                        break;
                    switch (reader.getFieldNumber()) {
                        case 1:
                            message.props_id = reader.readInt64();
                            break;
                        case 2:
                            message.props_val = reader.readInt64();
                            break;
                        default: reader.skipField();
                    }
                }
                return message;
            }
            serializeBinary(): Uint8Array {
                return this.serialize();
            }
            static deserializeBinary(bytes: Uint8Array): BonusData {
                return BonusData.deserialize(bytes);
            }
        }
        export class RespData extends pb_1.Message {
            #one_of_decls: number[][] = [];
            constructor(data?: any[] | {
                bonus_data?: SubmitSurveyResp.BonusData;
            }) {
                super();
                pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
                if (!Array.isArray(data) && typeof data == "object") {
                    if ("bonus_data" in data && data.bonus_data != undefined) {
                        this.bonus_data = data.bonus_data;
                    }
                }
            }
            get bonus_data() {
                return pb_1.Message.getWrapperField(this, SubmitSurveyResp.BonusData, 1) as SubmitSurveyResp.BonusData;
            }
            set bonus_data(value: SubmitSurveyResp.BonusData) {
                pb_1.Message.setWrapperField(this, 1, value);
            }
            get has_bonus_data() {
                return pb_1.Message.getField(this, 1) != null;
            }
            static fromObject(data: {
                bonus_data?: ReturnType<typeof SubmitSurveyResp.BonusData.prototype.toObject>;
            }): RespData {
                const message = new RespData({});
                if (data.bonus_data != null) {
                    message.bonus_data = SubmitSurveyResp.BonusData.fromObject(data.bonus_data);
                }
                return message;
            }
            toObject() {
                const data: {
                    bonus_data?: ReturnType<typeof SubmitSurveyResp.BonusData.prototype.toObject>;
                } = {};
                if (this.bonus_data != null) {
                    data.bonus_data = this.bonus_data.toObject();
                }
                return data;
            }
            serialize(): Uint8Array;
            serialize(w: pb_1.BinaryWriter): void;
            serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
                const writer = w || new pb_1.BinaryWriter();
                if (this.has_bonus_data)
                    writer.writeMessage(1, this.bonus_data, () => this.bonus_data.serialize(writer));
                if (!w)
                    return writer.getResultBuffer();
            }
            static deserialize(bytes: Uint8Array | pb_1.BinaryReader): RespData {
                const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new RespData();
                while (reader.nextField()) {
                    if (reader.isEndGroup())
                        break;
                    switch (reader.getFieldNumber()) {
                        case 1:
                            reader.readMessage(message.bonus_data, () => message.bonus_data = SubmitSurveyResp.BonusData.deserialize(reader));
                            break;
                        default: reader.skipField();
                    }
                }
                return message;
            }
            serializeBinary(): Uint8Array {
                return this.serialize();
            }
            static deserializeBinary(bytes: Uint8Array): RespData {
                return RespData.deserialize(bytes);
            }
        }
    }
}
